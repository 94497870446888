import React, { useContext, useState, useEffect } from "react";
import { Box, Button, Container, Grid, LinearProgress, Tab, Tabs, Typography, Alert, TextField, InputAdornment, IconButton } from "@mui/material";
import bkstApi from "../../common/api";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import ConfigContext from "../../common/context/ConfigContext";
import { AppStateContext } from "../../common/context/AppStateContext";
import ProductSummary from "../component/ProductSummary";
import { anyWordStartsWith, capitalize } from "../../common/util";
import AvailabilityCalendar from "../component/AvailabilityCalendar";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export default function Collection() {
  const siteConfig = useContext(ConfigContext);
  const { cart } = useContext(AppStateContext);

  const navigate = useNavigate();

  const [categoryMap, setCategoryMap] = useState("");

  const [query, setQuery] = useState("");
  const [filtered, setFiltered] = useState([]);

  const [category, setCategory] = useState("");
  const [products, setProducts] = useState([]);
  const [storeLocation, setStoreLocation] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    bkstApi(`/site/${siteConfig.id}/product/collection/shop`)
      .then((res) => {
        let sortedByRank = res.data.products.sort((o1, o2) => {
          let rslt = (+o2.rank || 0) - (+o1.rank || 0);
          if (rslt === 0) {
            rslt = o1.title > o2.title ? 1 : -1;
          }
          return rslt;
        });
        setProducts(sortedByRank);

        let map = {};
        for (const p of sortedByRank) {
          const cats = p.category || [p.type.toLowerCase()];
          for (let cat of cats) {
            cat = cat.toLowerCase();
            const arr = map[cat] || [];
            arr.push(p);
            map[cat] = arr;
          }
        }
        setCategoryMap(map);
        setStoreLocation(res.data.storeLocation);

        setLoading(false);

        if (window.location.hash) {
          setCategory(window.location.hash.substring(1).replace("-", " "));
        }
        setFiltered(sortedByRank);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  /*useEffect(() => {
    if (category) {
      console.log("inside useEffect", category, filtered.length);
      setTimeout(() => {
        scrollToCategory(category);
      }, 500);
    }
  }, [category]);*/

  const handleQueryChange = (q) => {
    setQuery(q);
    setCategory("");

    scrollToCategory("product-list");

    // add a delay to prevent shifting
    setTimeout(() => {
      setFiltered(products.filter((o) => anyWordStartsWith(o.title, q) || anyWordStartsWith(o.type, q)));
    }, 500);
  };

  const scrollToCategory = (cat) => {
    const el = document.getElementById(cat);
    if (el) {
      el.scrollIntoView();
    }
  };

  const getRank = (cat, arr) => {
    cat = cat.toLowerCase();
    if (cat.includes("featured")) return 10000;
    if (cat.includes("easter") || cat.includes("ramadan") || cat.includes("passover")) return 9000;

    const rank = arr.reduce((acc, o) => +acc + +o.rank, 0);
    const itemsWithEndDate = arr.filter((o) => o.rules?.date?.max);

    if (itemsWithEndDate.length > 0 && itemsWithEndDate.length / arr.length > 0.4) {
      // if half of items in category have end date, sort by end date
      return 8000;
    } else if (rank > arr.length) {
      // sort by bestsellers
      return rank;
    } else {
      // sort by number of items in category
      return arr.length; // all other strings
    }
  };

  const sortCategories = ([k1, v1], [k2, v2]) => {
    const rankA = getRank(k1, v1);
    const rankB = getRank(k2, v2);

    if (rankA === rankB) {
      return k1.localeCompare(k2);
    } else {
      return rankB - rankA;
    }
  };

  /** Using filtered instead of query since results are not rendered before this is executed */
  useEffect(() => {
    if (filtered.length === products.length) {
      if (category) {
        scrollToCategory(category);
      } else {
        scrollToCategory("product-list");
      }
    }
  }, [filtered]);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Box pb={20}>
        <Box
          sx={{
            backgroundColor: "#ECECEC",
            position: "-webkit-sticky",
            position: "sticky",
            top: "0",
            border: "1px solid #EEE",
            marginBottom: "2rem"
          }}
        >
          <Container maxWidth="lg" disableGutters>
            <Box>
              <Box px={1} pt={1}>
                <Box my={1} sx={{ backgroundColor: "#edf7ed" }}>
                  {cart?.quantity > 0 && (
                    <Alert
                      severity="success"
                      variant="filled"
                      action={
                        <Button color="inherit" size="small" component={RouterLink} to={`/cart/${cart.id}`}>
                          Proceed to Checkout
                        </Button>
                      }
                    >
                      You have {cart.quantity} items in your cart
                    </Alert>
                  )}
                </Box>
                <TextField
                  id="query"
                  value={query}
                  label=""
                  name="query"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    handleQueryChange(e.target.value);
                  }}
                  placeholder="Filter items by name or category"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment sx={{ paddingRight: "10px" }}>
                        <FilterListIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment>
                        {query && (
                          <IconButton onClick={(e) => handleQueryChange("")}>
                            <CloseOutlinedIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    )
                  }}
                  sx={{ backgroundColor: "#FFF", borderRadius: "4px" }}
                />
              </Box>
              <Tabs value={category} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                {Object.entries(categoryMap)
                  .sort(sortCategories)
                  .map(([cat, arr], idx) => (
                    <Tab
                      key={idx}
                      label={cat}
                      value={cat}
                      onClick={() => {
                        setCategory(cat);
                        if (query) {
                          setQuery("");
                          setFiltered(products);
                        } else {
                          // category list is already rendered, simply scroll to category
                          scrollToCategory(cat);
                        }
                      }}
                      sx={{ minWidth: "50px", padding: "12px" }}
                    />
                  ))}
              </Tabs>
            </Box>
          </Container>
        </Box>
        <Container maxWidth="lg">
          <Box id="product-list" sx={{ scrollMarginTop: "150px" }}>
            {/* <Box
            sx={{
              backgroundImage:
                "linear-gradient(45deg , #ECECEC 10%,transparent 11%, transparent 89% , #ECECEC 90%),linear-gradient(135deg , #ECECEC 10%,transparent 11%, transparent 89% , #ECECEC 90%),radial-gradient(circle, transparent 25%, #FEFEFE  26%),linear-gradient(0deg, transparent 44%, #ECECEC 45%, #ECECEC 55%, transparent 56%), linear-gradient(90deg, transparent 44%, #ECECEC 45%, #ECECEC 55%, transparent 56%)",
              backgroundSize: "2em 2em"
            }}
          >*/}
            <AvailabilityCalendar storeLocation={storeLocation} />
            {filtered.length < products.length ? (
              <Grid container spacing={3}>
                {filtered.map((o, index) => {
                  return <ProductSummary key={`rslt-${index}`} product={o} query={query} />;
                })}
              </Grid>
            ) : (
              Object.entries(categoryMap)
                .sort(sortCategories)
                .map(([key, value]) => {
                  return (
                    <Box mb={4} id={key} sx={{ scrollMarginTop: "150px" }}>
                      <Box mb={2} textAlign={"left"}>
                        <Typography variant="h4" color="textSecondary">
                          {capitalize(key)}
                        </Typography>
                      </Box>
                      <Grid container spacing={3}>
                        {categoryMap[key].map((product, index) => {
                          return <ProductSummary key={index} product={product} />;
                        })}
                      </Grid>
                    </Box>
                  );
                })
            )}
          </Box>
        </Container>
      </Box>
    );
  }
}
